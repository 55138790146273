import { CreatorSubscriptionBillingCycle } from 'core/models/CreatorSettings';
import type { RdsConfig } from 'core/utils/aws/rds';

export const NODE_ENV = process.env.NODE_ENV;

// Secrets pulled from Vercel env
export const SEXWORKER_DOMAIN = process.env.NEXT_PUBLIC_SEXWORKER_DOMAIN;
export const SWER_DOMAIN = process.env.NEXT_PUBLIC_SWER_DOMAIN;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const DISCORD_INVITE_CODE = process.env.NEXT_PUBLIC_DISCORD_INVITE_CODE;
export const PG_WRITER_HOST = process.env.PG_WRITER_HOST;
export const PG_READER_HOST = process.env.PG_READER_HOST;
export const PG_USER = process.env.PG_USER;
export const PG_PASSWORD = process.env.PG_PASSWORD;
export const PG_DATABASE = process.env.PG_DATABASE;
export const PG_PORT = 5432;
export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY;
export const PROFILE_PICTURE_CDN_DOMAIN =
  process.env.NEXT_PUBLIC_PROFILE_PICTURE_CDN_DOMAIN;
export const COVER_PHOTO_CDN_DOMAIN =
  process.env.NEXT_PUBLIC_COVER_PHOTO_CDN_DOMAIN;
export const FORUM_POST_IMAGE_CDN_DOMAIN =
  process.env.NEXT_PUBLIC_FORUM_POST_IMAGE_CDN_DOMAIN;
export const IDENTITY_VERIFICATION_CDN_DOMAIN =
  process.env.NEXT_PUBLIC_IDENTITY_VERIFICATION_CDN_DOMAIN;
// remember to add new CDN domains to the DNS_PREFETCH_DOMAINS array below
export const SITEMAPS_DOMAIN = process.env.NEXT_PUBLIC_SITEMAPS_DOMAIN;
export const OG_IMAGE_DOMAIN = process.env.NEXT_PUBLIC_OG_IMAGE_DOMAIN;

export const AWS_ACCESS_KEY_ID =
  process.env.AWS_ACCESS_KEY_ID_X || process.env.AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY =
  process.env.AWS_SECRET_ACCESS_KEY_X || process.env.AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.AWS_REGION_X || process.env.AWS_REGION;

export const DNS_PREFETCH_DOMAINS = [
  COVER_PHOTO_CDN_DOMAIN,
  FORUM_POST_IMAGE_CDN_DOMAIN,
  PROFILE_PICTURE_CDN_DOMAIN,
  IDENTITY_VERIFICATION_CDN_DOMAIN,
];

export const PG_DEBUG_QUERY = true;
export const PG_DEBUG_EXECUTION_TIME = false;

export const RDS_CONFIG: RdsConfig = {
  writerHost: PG_WRITER_HOST,
  readerHost: PG_READER_HOST,
  port: PG_PORT,
  user: PG_USER,
  password: PG_PASSWORD,
  database: PG_DATABASE,
  debugQuery: PG_DEBUG_QUERY,
  debugExecutionTime: PG_DEBUG_EXECUTION_TIME,
};

// Google Analytics
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

// Site Info
export const DEV_DOMAIN = 'localhost:3000';
export const STAGE_DOMAIN = 'stage.sexworker.com';
export const PROD_DOMAIN = 'sexworker.com';

export const SITE_NAME = '___';
export const SITE_DESCRIPTION =
  'SexWorker is a database of adult content creators.';

export const TWITTER_USERNAME = 'SWer';
export const TWITTER_URL = `https://twitter.com/${TWITTER_USERNAME}`;
export const DISCORD_INVITE_URL = `https://discord.gg/${DISCORD_INVITE_CODE}`;

export const COOKIE_PREFIX = 'sw_';
export const SESSION_COOKIE_NAME = `${COOKIE_PREFIX}session`;
export const SESSION_EXPIRY_MINS = 43800; // 1 month
export const SESSION_TOKEN_LENGTH = 48;

export const SHOW_HOMEPAGE_CREATORS = true;

export const PASSWORD_MIN_LENGTH = 6;
export const BCRYPT_SALT_ROUNDS = 10;
export const USERNAME_MIN_LENGTH = 5;
export const USERNAME_MAX_LENGTH = 16;
export const USERNAME_REGEX = new RegExp(
  `^[a-zA-Z0-9_]{${USERNAME_MIN_LENGTH},${USERNAME_MAX_LENGTH}}$`
);
export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 32;
export const ABOUT_MAX_LENGTH = 1000;
export const STATUS_MAX_LENGTH = 80;
export const EMAIL_ADDRESS_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i; // eslint-disable-line no-control-regex
export const EMAIL_VERIFICATION_CODE_LENGTH = 6;
export const EMAIL_VERIFICATION_CODE_REGEX = new RegExp(
  `^\\d{${EMAIL_VERIFICATION_CODE_LENGTH}}$`
);
export const EMAIL_VERIFICATION_CODE_EXPIRY_MINS = 60;
export const EMAIL_MAX_LENGTH = 128;
export const RESET_PASSWORD_REQUEST_EXPIRY_HOURS = 1;
export const URL_REGEX_OPTIONAL_PROTOCOL =
  /(?:https?:\/\/)?(?:www\.)?(?<url>[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=\$]*))/;
export const URL_REGEX_REQUIRED_PROTOCOL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=\$]*)/;
export const ID_VERIFICATION_FIRST_NAME_MIN_LENGTH = 2;
export const ID_VERIFICATION_FIRST_NAME_MAX_LENGTH = 48;
export const ID_VERIFICATION_LAST_NAME_MIN_LENGTH = 2;
export const ID_VERIFICATION_LAST_NAME_MAX_LENGTH = 48;
export const TWITTER_USERNAME_MIN_LENGTH = 3; // actually 4 but old accounts may be shorter
export const TWITTER_USERNAME_MAX_LENGTH = 15;
export const TWITTER_USERNAME_REGEX = new RegExp(
  `^[a-zA-Z0-9_]{${TWITTER_USERNAME_MIN_LENGTH},${TWITTER_USERNAME_MAX_LENGTH}}$`
);
export const MAX_CREATOR_LINKS = 50;
export const CREATOR_LINK_NAME_MAX_LENGTH = 32;
export const META_DESCRIPTION_MAX_CHARS = 135;

export const SEND_MAIL_ON_DEV_SERVER = true;
export const SENDGRID_FROM_EMAIL = 'no-reply@sexworker.com';
export const SENDGRID_FROM_NAME = SITE_NAME;
export const SENDGRID_EMAIL_VERIFICATION_TEMPLATE_ID =
  'd-5316573c325d461298d9bd839d47b4c1';
export const SENDGRID_RESET_PASSWORD_TEMPLATE_ID =
  'd-4daf2935236f4fdba57acbd4b98a9a53';
export const SENDGRID_CREATOR_APPLICANT_SUBMITTED_TEMPLATE_ID =
  'd-ba720ba440234aeca78639f63318879e';
export const SENDGRID_CREATOR_APPLICANT_APPROVED_TEMPLATE_ID =
  'd-78a9e6e2dde544d181c83c2c3e31cc32';
export const SENDGRID_CREATOR_APPLICANT_REJECTED_TEMPLATE_ID =
  'd-892c3302fe61432db1e9b4c9eee92365';

export const DEFAULT_PROFILE_PICTURE_FILENAME = 'default.jpg';
export const DEFAULT_PROFILE_PICTURE_URL = `https://${PROFILE_PICTURE_CDN_DOMAIN}/${DEFAULT_PROFILE_PICTURE_FILENAME}`;
export const DEFAULT_USERNAME_PREFIX = 'user_';
export const DEFAULT_USERNAME_HASH_CHARS = '0123456789abcdef';
export const DEFAULT_USERNAME_HASH_LENGTH = 8;
export const DEFAULT_NAME = 'Anonymous';
export const DEFAULT_COVER_PHOTO_FILENAME = 'default.jpg';
export const DEFAULT_COVER_PHOTO_URL = `https://${COVER_PHOTO_CDN_DOMAIN}/${DEFAULT_COVER_PHOTO_FILENAME}`;

export const BADGES_API_POLL_INTERVAL_SECS = 20; // prod: 20

export const AXIOS_TIMEOUT_SECS = 60;

export const COVER_PHOTO_MAX_FILESIZE_BYTES = 20000000; // 20 MB
export const FORUM_POST_IMAGE_MAX_FILESIZE_BYTES = 10000000; // 10 MB
export const IDENTITY_VERIFICATION_SELFIE_PHOTO_MAX_FILESIZE_BYTES = 20000000; // 20 MB
export const PROFILE_PICTURE_MAX_FILESIZE_BYTES = 10000000; // 10 MB

export const SEARCH_PER_PAGE_OPTIONS = [30, 50, 100];
export const DEFAULT_SEARCH_PER_PAGE = 30;
export const DEFAULT_SEARCH_SORT_OPTION = 'recent';
export const DEFAULT_SEARCH_SORT_COLUMN = 'c.date_approved';
export const DEFAULT_SEARCH_SORT_DIRECTION = 'DESC';

export const FORUM_THREADS_PER_PAGE = 20;
export const DEFAULT_FORUM_THREADS_SCOPE_OPTION = 'all';
export const DEFAULT_FORUM_THREADS_SORT_OPTION = 'recent';
export const DEFAULT_FORUM_THREADS_SORT_COLUMN = 'ft.date_latest_post';
export const DEFAULT_FORUM_THREADS_SORT_DIRECTION = 'DESC';

export const FORUM_THREAD_REPLIES_PER_PAGE = 10;
export const FORUM_THREAD_BODY_MIN_LENGTH = 3;
export const FORUM_THREAD_BODY_MAX_LENGTH = 5000;
export const FORUM_POST_BODY_MIN_LENGTH = 3;
export const FORUM_POST_BODY_MAX_LENGTH = 5000;
export const FORUM_THREAD_TITLE_MIN_LENGTH = 5;
export const FORUM_THREAD_TITLE_MAX_LENGTH = 256;
export const FORUM_THREAD_RATE_LIMIT_MINS = 3;
export const FORUM_POST_RATE_LIMIT_MINS = 1;

export const MARKDOWN_TEXTAREA_LINK_TEXT_MAX_LENGTH = 256;

// NOTE: All of these are defined in cents. In the future we will support a full pricing matrix
export const PRICE_TIERS = [
  0, 99, 199, 299, 399, 499, 599, 699, 799, 899, 999, 1099, 1199, 1299, 1399,
  1499, 1599, 1699, 1799, 1899, 1999, 2499, 2999, 3499, 3999, 4499, 4999, 5499,
  5999, 6499, 6999, 7499, 7999, 8499, 8999, 9499, 9999, 14999, 19999, 24999,
  29999, 34999, 39999, 44999, 49999, 54999, 59999, 64999, 69999, 74999, 79999,
  84999, 89999, 94999, 99999,
];

export const DAYS = Array.from({ length: 31 }, (_, i) => i + 1);
export const MONTHS: {
  value: number;
  name: string;
}[] = [
  { value: 1, name: 'January' },
  { value: 2, name: 'February' },
  { value: 3, name: 'March' },
  { value: 4, name: 'April' },
  { value: 5, name: 'May' },
  { value: 6, name: 'June' },
  { value: 7, name: 'July' },
  { value: 8, name: 'August' },
  { value: 9, name: 'September' },
  { value: 10, name: 'October' },
  { value: 11, name: 'November' },
  { value: 12, name: 'December' },
];
export const DOB_YEARS = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - i
);
export const ID_VERIFICATION_DOCUMENT_EXPIRY_YEARS = Array.from(
  { length: 20 },
  (_, i) => new Date().getFullYear() + (i - 1)
);

export const SUBSCRIPTION_RECOMMENDED_PRICE_TIER_RANGE = [99, 999];
export const SUBSCRIPTION_PRICE_TIER_RANGE = [0, 9999];
export const SUBSCRIPTION_DEFAULT_PRICE_TIER = 499;
export const SUBSCRIPTION_DEFAULT_BILLING_CYCLE =
  CreatorSubscriptionBillingCycle.Monthly;
export const SUBSCRIPTION_DEFAULT_SHOW_COUNT = false;
export const SUBSCRIPTION_DEFAULT_PUBLIC_POSTS = true;
export const SUBSCRIPTION_DEFAULT_PRIVATE_POST_PREVIEWS = true;

export const PAY_PER_MESSAGE_RECOMMENDED_PRICE_TIER_RANGE = [99, 999];
export const PAY_PER_MESSAGE_PRICE_TIER_RANGE = [99, 9999];

export const INITIAL_TRIBUTE_RECOMMENDED_PRICE_TIER_RANGE = [1999, 3999];
export const INITIAL_TRIBUTE_PRICE_TIER_RANGE = [99, 99999];

export const SUCCESS_ALERT_TIMEOUT_MS = 3000;
export const COPY_TO_CLIPBOARD_TIMEOUT_MS = 2000;

export const UK_VAT_MARGIN_RATE = 16.67;

export const PLATFORM_DOMAIN_NAME_MAP: {
  color: string;
  domain: string;
  name: string;
}[] = [
  // Adult Platforms
  { name: '4fans.com', domain: '4Fans', color: '#9a29bc' },
  { name: '4MyFans', domain: '4my.fans', color: '#554295' },
  { name: 'AddFriends', domain: 'addfriends.com', color: '#000000' },
  { name: 'AdmireMe', domain: 'admireme.vip', color: '#566983' },
  { name: 'AdultWork', domain: 'adultwork.com', color: '#a601b5' },
  { name: 'AdultNode', domain: 'adultnode.com', color: '#253258' },
  { name: 'All Things Worn', domain: 'allthingsworn.com', color: '#da3178' },
  { name: 'Alua', domain: 'alua.com', color: '#1c2643' },
  { name: 'APClips', domain: 'apclips.com', color: '#3bb2e6' },
  { name: 'AVN Stars', domain: 'stars.avn.com', color: '#d0352c' },
  { name: 'Babestation', domain: 'babestation.com', color: '#c33a9a' },
  { name: 'Babestation', domain: 'babestation.tv', color: '#c33a9a' },
  { name: 'Babestation Cams', domain: 'babestationcams.com', color: '#c33a9a' },
  { name: 'Blisss', domain: 'blisss.company', color: '#555770' },
  { name: 'BongaCams', domain: 'bongacams.com', color: '#932e3b' },
  { name: 'CAM4', domain: 'cam4.com', color: '#e96034' },
  { name: 'CamGasm', domain: 'camgasm.live', color: '#4e7ecd' },
  { name: 'Camistry', domain: 'camistry.live', color: '#8d28cf' },
  { name: 'CamModels', domain: 'cammodels.com', color: '#6bbdcd' },
  { name: 'CamSoda', domain: 'camsoda.com', color: '#4eacf4' },
  { name: 'Centerfold', domain: 'centerfold.com', color: '#000000' },
  { name: 'Chat Recruit', domain: 'chatrecruit.com', color: '#d85790' },
  { name: 'ChataBox', domain: 'chatabox.com', color: '#50a3ee' },
  { name: 'Chaturbate', domain: 'chaturbate.com', color: '#e99a3f' },
  { name: 'Cherry.tv', domain: 'cherry.tv', color: '#e44667' },
  { name: 'ClipFoo', domain: 'clipfoo.com', color: '#efa743' },
  { name: 'Clips4Sale', domain: 'clips4sale.com', color: '#34264d' },
  { name: 'ClipTeez', domain: 'clipteez.com', color: '#000000' },
  { name: 'CumRocket', domain: 'cumrocket.io', color: '#e431cf' },
  { name: 'Dares.TV', domain: 'dares.tv', color: '#c33a3b' },
  { name: 'eBanned', domain: 'ebanned.net', color: '#9e2963' },
  { name: 'ECCIE', domain: 'eccie.net', color: '#27355c' },
  { name: 'ePlay', domain: 'eplay.com', color: '#ea3367' },
  { name: 'EscortBook', domain: 'escortbook.com', color: '#000' },
  { name: 'Escort Directory', domain: 'escortdirectory.com', color: '#c4a582' },
  { name: 'Escort Fans', domain: 'escortfans.com', color: '#ad3270' },
  { name: 'Exclusive Life', domain: 'exclusv.life', color: '#56bca6' },
  { name: 'Exclusive Link', domain: 'exclusive-link.com', color: '#e4be6c' },
  { name: 'ExtraLunchMoney', domain: 'extralunchmoney.com', color: '#7da356' },
  { name: 'Fanbase', domain: 'fanbase.com', color: '#73d7ad' },
  { name: 'FanCentro', domain: 'fancentro.com', color: '#3d3454' },
  { name: 'FanCentro', domain: 'fcl.ink', color: '#3d3454' },
  { name: 'Fangage', domain: 'fangage.com', color: '#e85533' },
  { name: 'FanGear', domain: 'fangear.vip', color: '#4faf32' },
  { name: 'Fanlr', domain: 'fanlr.com', color: '#6cd4b8' },
  { name: 'fanseven', domain: 'fanseven.com', color: '#aa22b7' },
  { name: 'FansMine', domain: 'fansmine.com', color: '#de3286' },
  { name: 'Fanter', domain: 'fanter.com', color: '#564ca6' },
  { name: 'FapHouse', domain: 'faphouse.com', color: '#ebbd4e' },
  { name: 'FanSoda', domain: 'fansoda.com', color: '#e551b1' },
  { name: 'Fansly', domain: 'fansly.com', color: '#4b97f0' },
  { name: 'Fansly', domain: 'fans.ly', color: '#4b97f0' },
  { name: 'Fanvue', domain: 'fanvue.com', color: '#e7666a' },
  { name: 'FeetFinder', domain: 'feetfinder.com', color: '#ea33b5' },
  { name: 'FetLife', domain: 'fetlife.com', color: '#8c1a11' },
  { name: 'FetLife', domain: 'fetlife.vip', color: '#8c1a11' },
  { name: 'Frisk', domain: 'frisk.chat', color: '#807af4' },
  { name: 'Findomcenter', domain: 'findomcenter.com', color: '#e2958d' },
  { name: 'Flirt4Free', domain: 'flirt4free.com', color: '#4ea6db' },
  { name: 'iFans', domain: 'ifans.com', color: '#ed6379' },
  { name: 'IsMyGirl', domain: 'ismygirl.com', color: '4ba7f8' },
  { name: 'IWantClips', domain: 'iwantclips.com', color: '#dd5c95' },
  { name: 'Jerkmate', domain: 'jerkmate.com', color: '#19185e' },
  { name: 'JustForFans', domain: 'justfor.fans', color: '#bf2d60' },
  { name: 'KinkBomb', domain: 'kinkbomb.com', color: '#ea389c' },
  { name: 'LiveUnicorns', domain: 'liveunicorns.com', color: '#9c5be8' },
  { name: 'Loverfans', domain: 'loverfans.com', color: '#e33857' },
  { name: 'LoyalFans', domain: 'loyalfans.com', color: '#c72d26' },
  { name: 'ManyVids', domain: 'manyvids.com', color: '#eb5181' },
  { name: 'ManyVids', domain: 'vipfanclub.com', color: '#8c53bf' },
  { name: 'MegaCams', domain: 'megacams.me', color: '#f7d16b' },
  { name: 'MyDirtyHobby', domain: 'mydirtyhobby.com', color: '#bb403b' },
  { name: 'MyFet', domain: 'myfet.com', color: '#ae3434' },
  { name: 'MyFreeCams', domain: 'mfc.im', color: '#377e22' },
  { name: 'MyFreeCams', domain: 'mfc.me', color: '#377e22' },
  { name: 'MyFreeCams', domain: 'myfreecams.com', color: '#377e22' },
  { name: 'MFC Share', domain: 'mfcsha.re', color: '#377e22' },
  { name: 'ModelCentro', domain: 'modelcentro.com', color: '#3b78ba' },
  { name: 'Modelhub', domain: 'modelhub.com', color: '#f09536' },
  { name: 'My.Club', domain: 'my.club', color: '#984ff3' },
  { name: 'MyFans.stream', domain: 'myfans.stream', color: '#49209a' },
  { name: 'mygurlfund', domain: 'mygurlfund.com', color: '#e1526f' },
  { name: 'MYM', domain: 'mym.fans', color: '#3d74f6' },
  { name: 'myonly.chat', domain: 'myonly.chat', color: '#ea96a0' },
  { name: 'My VIP Fans', domain: 'myvipfans.com', color: '#d93832' },
  { name: 'Naked', domain: 'naked.com', color: '#c71313' },
  { name: 'NiteFlirt', domain: 'niteflirt.com', color: '#000000' },
  { name: 'OnlyFans', domain: 'onlyfans.com', color: '#00aff0' },
  { name: 'OnlyFans', domain: 'of.com', color: '#00aff0' },
  { name: 'Panty', domain: 'panty.com', color: '#d63864' },
  { name: 'pantydeal', domain: 'pantydeal.com', color: '#b07d96' },
  { name: 'Paychat', domain: 'getpaychat.com', color: '#eb574c' },
  { name: 'Peach', domain: 'peach.com', color: '#f09864' },
  { name: 'Phrendly', domain: 'phrendly.com', color: '#db556f' },
  { name: 'PocketStars', domain: 'pocketstars.com', color: '#ec6560' },
  { name: 'PornDoe', domain: 'porndoe.com', color: '#000000' },
  { name: 'PornHub', domain: 'pornhub.com', color: '#f09536' },
  { name: 'Preferred411', domain: 'preferred411.com', color: '#ab9196' },
  { name: 'Privacy', domain: 'privacy.com.br', color: '#ed7d59' },
  { name: 'Privat Cam', domain: 'privat-cam.com', color: '#d9a83a' },
  { name: 'PrivateDelights', domain: 'privatedelights.ch', color: '#546ced' },
  { name: 'RedGifs', domain: 'redgifs.com', color: '#cd345a' },
  { name: 'RevealMe', domain: 'revealme.vip', color: '#b6954d' },
  { name: 'RocketStars', domain: 'rocketstars.io', color: '#ec6560' },
  { name: 'RVLD', domain: 'rvld.me', color: '#ea9262' },
  { name: 'Scatbook', domain: 'scatbook.com', color: '#a5642e' },
  { name: 'Seeking Arrangement', domain: 'seeking.com', color: '#a4302d' },
  { name: 'Sex.com', domain: 'sex.com', color: '#4960e6' },
  { name: 'SextPanther', domain: 'sextpanther.com', color: '#ea4065' },
  { name: 'SexWorker', domain: 'sexworker.com', color: '#000000' },
  { name: 'SexWorker', domain: 'swer.com', color: '#000000' },
  { name: 'SkyPrivate', domain: 'skyprivate.com', color: '#458abb' },
  { name: 'SmuttyFans', domain: 'smuttyfans.com', color: '#d25b7c' },
  { name: 'SmuttyFy', domain: 'smuttyfy.com', color: '#462f6e' },
  { name: 'snifffr', domain: 'snifffr.com', color: '#d66594' },
  { name: 'Sofia Gray', domain: 'sofiagray.com', color: '#e43362' },
  { name: 'SoSpoilt', domain: 'sospoilt.com', color: '#f5c14b' },
  { name: 'Sssh', domain: 'sssh.com', color: '#000000' },
  { name: 'Streamate', domain: 'streamate.com', color: '#5197c7' },
  { name: 'Stripchat', domain: 'stripchat.com', color: '#953032' },
  { name: 'SoulCams', domain: 'soulcams.com', color: '#310568' },
  { name: 'Sprely', domain: 'sprely.com', color: '#78b3f0' },
  { name: 'SubscribeStar', domain: 'subscribestar.adult', color: '#70b6ae' },
  { name: 'SugarBounce', domain: 'sugarbounce.com', color: '#ea343d' },
  { name: 'SuicideGirls', domain: 'suicidegirls.com', color: '#d28b9e' },
  { name: 'Switter', domain: 'switter.at', color: '#4986ce' },
  { name: 'Tempted', domain: 'tempted.com', color: '#dd5042' },
  { name: 'TipSnaps', domain: 'tipsnaps.com', color: '#8f3ad9' },
  { name: 'Tryst', domain: 'tryst.link', color: '#c63b38' },
  { name: 'Unlockd', domain: 'unlockd.me', color: '#da3569' },
  { name: 'Write for me', domain: 'writeforme.org', color: '#b94822' },
  { name: 'xHamster', domain: 'xhamster.com', color: '#303030' },
  { name: 'XPanded', domain: 'xpanded.com', color: '#f5c249' },
  { name: 'XVideos', domain: 'xvideos.com', color: '#000000' },
  { name: 'YouPorn', domain: 'youporn.com', color: '#db607d' },

  // Socials
  { name: 'AllMyLinks', domain: 'allmylinks.com', color: '#eb5181' },
  { name: 'Beacons', domain: 'beacons.ai', color: '#000' },
  { name: 'Depop', domain: 'depop.com', color: '#ea3f25' },
  { name: 'Discord', domain: 'discord.com', color: '#5965ea' },
  { name: 'Etsy', domain: 'etsy.com', color: '#e06d35' },
  { name: 'Facebook', domain: 'facebook.com', color: '#4a66ad' },
  { name: 'FanHouse', domain: 'fanhouse.app', color: '#ec7593' },
  { name: 'Gumroad', domain: 'gumroad.com', color: '#000000' },
  { name: 'Instagram', domain: 'instagram.com', color: '#c037a0' },
  { name: 'Ko-fi', domain: 'ko-fi.com', color: '#e26737' },
  { name: 'Linktree', domain: 'linktree.com', color: '#71dda0' },
  { name: 'Linktree', domain: 'linktr.ee', color: '#71dda0' },
  { name: 'mastodon', domain: 'mastodon.social', color: '#4a8ed3' },
  { name: 'Patreon', domain: 'patreon.com', color: '#eb5354' },
  { name: 'Pinterest', domain: 'pinterest.com', color: '#d32d2f' },
  { name: 'Reddit', domain: 'reddit.com', color: '#eb5528' },
  { name: 'Snapchat', domain: 'snapchat.com', color: '#fffc54' },
  { name: 'Telegram', domain: 't.me', color: '#53a3dc' },
  { name: 'Telegram', domain: 'telegram.org', color: '#53a3dc' },
  { name: 'TikTok', domain: 'tiktok.com', color: '#000000' },
  { name: 'Tumblr', domain: 'tumblr.com', color: '#233662' },
  { name: 'Twitter', domain: 'mobile.twitter.com', color: '#1da1f2' },
  { name: 'Twitter', domain: 'twitter.com', color: '#1da1f2' },
  { name: 'Vimeo', domain: 'vimeo.com', color: '#000000' },
  { name: 'VSCO', domain: 'vsco.co', color: '#000000' },
  { name: 'YouTube', domain: 'youtube.com', color: '#ea3323' },
  { name: 'YouTube', domain: 'youtu.be', color: '#ea3323' },

  // Payments
  { name: 'Buy Me a Coffee', domain: 'buymeacoffee.com', color: '#f9de4b' },
  { name: 'CashApp', domain: 'cash.app', color: '#60d25e' },
  { name: 'CashApp', domain: 'cash.me', color: '#60d25e' },
  { name: 'DonationAlerts', domain: 'donationalerts.com', color: '#e78f35' },
  { name: 'GoFundMe', domain: 'gofundme.com', color: '#4ba664' },
  { name: 'GoFundMe', domain: 'gofund.me', color: '#4ba664' },
  { name: 'PayPal', domain: 'paypal.com', color: '#2a3a7c' },
  { name: 'PayPal', domain: 'paypal.me', color: '#2a3a7c' },
  { name: 'Shopify', domain: 'shopify.com', color: '#99b855' },
  { name: 'SpankPay', domain: 'spankpay.com', color: '#eb4e81' },
  { name: 'SpankPay', domain: 'spankpay.me', color: '#eb4e81' },
  { name: 'Tikkie', domain: 'tikkie.me', color: '#6ab4ac' },
  { name: 'Venmo', domain: 'venmo.com', color: '#3d8af7' },
  { name: 'Verse', domain: 'verse.me', color: '#63aff8' },

  // Wishlists
  { name: 'Amazon Wishlist', domain: 'amazon.com', color: '#f19e38' },
  { name: 'Amazon Wishlist', domain: 'amazon.co.uk', color: '#f19e38' },
  { name: 'Amazon Wishlist', domain: 'amazon.ca', color: '#f19e38' },
  { name: 'Amazon Wishlist', domain: 'amazon.it', color: '#f19e38' },
  { name: 'Giftful', domain: 'giftful.com', color: '#5bb38f' },
  { name: 'Wishlist (Throne)', domain: 'jointhrone.com', color: '#6c60d7' },
  { name: 'Wishlist (Throne)', domain: 'thrn.co', color: '#6c60d7' },
  { name: 'Wishlist', domain: 'thingstogetme.com', color: '#3f4761' },
  { name: 'Wishlistr', domain: 'wishlistr.com', color: '#bce0fc' },
];

// ISO 3166-1 alpha 2
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
export const COUNTRIES: {
  value: string;
  name: string;
}[] = [
  { value: 'af', name: 'Afghanistan' },
  { value: 'ax', name: 'Åland Islands' },
  { value: 'al', name: 'Albania' },
  { value: 'dz', name: 'Algeria' },
  { value: 'as', name: 'American Samoa' },
  { value: 'ad', name: 'Andorra' },
  { value: 'ao', name: 'Angola' },
  { value: 'ai', name: 'Anguilla' },
  { value: 'aq', name: 'Antarctica' },
  { value: 'ag', name: 'Antigua and Barbuda' },
  { value: 'ar', name: 'Argentina' },
  { value: 'am', name: 'Armenia' },
  { value: 'aw', name: 'Aruba' },
  { value: 'au', name: 'Australia' },
  { value: 'at', name: 'Austria' },
  { value: 'az', name: 'Azerbaijan' },

  { value: 'bs', name: 'Bahamas' },
  { value: 'bh', name: 'Bahrain' },
  { value: 'bd', name: 'Bangladesh' },
  { value: 'bb', name: 'Barbados' },
  { value: 'by', name: 'Belarus' },
  { value: 'be', name: 'Belgium' },
  { value: 'bz', name: 'Belize' },
  { value: 'bj', name: 'Benin' },
  { value: 'bm', name: 'Bermuda' },
  { value: 'bt', name: 'Bhutan' },
  { value: 'bo', name: 'Bolivia (Plurinational State of)' },
  { value: 'bq', name: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'ba', name: 'Bosnia and Herzegovina' },
  { value: 'bw', name: 'Botswana' },
  { value: 'bv', name: 'Bouvet Island' },
  { value: 'br', name: 'Brazil' },
  { value: 'io', name: 'British Indian Ocean Territory' },
  { value: 'bn', name: 'Brunei Darussalam' },
  { value: 'bg', name: 'Bulgaria' },
  { value: 'bf', name: 'Burkina Faso' },
  { value: 'bi', name: 'Burundi' },

  { value: 'cv', name: 'Cabo Verde' },
  { value: 'kh', name: 'Cambodia' },
  { value: 'cm', name: 'Cameroon' },
  { value: 'ca', name: 'Canada' },
  { value: 'ky', name: 'Cayman Islands' },
  { value: 'cf', name: 'Central African Republic' },
  { value: 'td', name: 'Chad' },
  { value: 'cl', name: 'Chile' },
  { value: 'cn', name: 'China' },
  { value: 'cx', name: 'Christmas Island' },
  { value: 'cc', name: 'Cocos' },
  { value: 'co', name: 'Colombia' },
  { value: 'km', name: 'Comoros' },
  { value: 'cg', name: 'Congo' },
  { value: 'cd', name: 'Congo, Democratic Republic of the' },
  { value: 'ck', name: 'Cook Islands' },
  { value: 'cr', name: 'Costa Rica' },
  { value: 'ci', name: `Côte d'Ivoire` },
  { value: 'hr', name: 'Croatia' },
  { value: 'cu', name: 'Cuba' },
  { value: 'cw', name: 'Curaçao' },
  { value: 'cy', name: 'Cyprus' },
  { value: 'cz', name: 'Czechia' },

  { value: 'dk', name: 'Denmark' },
  { value: 'dj', name: 'Djibouti' },
  { value: 'dm', name: 'Dominica' },
  { value: 'do', name: 'Dominican Republic' },

  { value: 'ec', name: 'Ecuador' },
  { value: 'eg', name: 'Egypt' },
  { value: 'sv', name: 'El Salvador' },
  { value: 'gq', name: 'Equatorial Guinea' },
  { value: 'er', name: 'Eritrea' },
  { value: 'ee', name: 'Estonia' },
  { value: 'sz', name: 'Eswatini' },
  { value: 'et', name: 'Ethiopia' },

  { value: 'fk', name: 'Falkland Islands (Malvinas)' },
  { value: 'fo', name: 'Faroe Islands' },
  { value: 'fj', name: 'Fiji' },
  { value: 'fi', name: 'Finland' },
  { value: 'fr', name: 'France' },
  { value: 'gf', name: 'French Guiana' },
  { value: 'pf', name: 'French Polynesia' },
  { value: 'tf', name: 'French Southern Territories' },

  { value: 'ga', name: 'Gabon' },
  { value: 'gm', name: 'Gambia' },
  { value: 'ge', name: 'Georgia' },
  { value: 'de', name: 'Germany' },
  { value: 'gh', name: 'Ghana' },
  { value: 'gi', name: 'Gibraltar' },
  { value: 'gr', name: 'Greece' },
  { value: 'gl', name: 'Greenland' },
  { value: 'gd', name: 'Grenada' },
  { value: 'gp', name: 'Guadeloupe' },
  { value: 'gu', name: 'Guam' },
  { value: 'gt', name: 'Guatemala' },
  { value: 'gg', name: 'Guernsey' },
  { value: 'gn', name: 'Guinea' },
  { value: 'gw', name: 'Guinea-Bissau' },
  { value: 'gy', name: 'Guyana' },

  { value: 'ht', name: 'Haiti' },
  { value: 'hm', name: 'Heard Island and McDonald Islands' },
  { value: 'va', name: 'Holy See' },
  { value: 'hn', name: 'Honduras' },
  { value: 'hk', name: 'Hong Kong' },
  { value: 'hu', name: 'Hungary' },

  { value: 'is', name: 'Iceland' },
  { value: 'in', name: 'India' },
  { value: 'id', name: 'Indonesia' },
  { value: 'ir', name: 'Iran' },
  { value: 'iq', name: 'Iraq' },
  { value: 'ie', name: 'Ireland' },
  { value: 'im', name: 'Isle of Man' },
  { value: 'il', name: 'Israel' },
  { value: 'it', name: 'Italy' },

  { value: 'jm', name: 'Jamaica' },
  { value: 'jp', name: 'Japan' },
  { value: 'je', name: 'Jersey' },
  { value: 'jo', name: 'Jordan' },

  { value: 'kz', name: 'Kazakhstan' },
  { value: 'ke', name: 'Kenya' },
  { value: 'ki', name: 'Kiribati' },
  { value: 'kp', name: 'Korea' },
  { value: 'kr', name: 'Korea, Republic of' },
  { value: 'kw', name: 'Kuwait' },
  { value: 'kg', name: 'Kyrgyzstan' },

  { value: 'la', name: `Lao People's Democratic Republic` },
  { value: 'lv', name: 'Latvia' },
  { value: 'lb', name: 'Lebanon' },
  { value: 'ls', name: 'Lesotho' },
  { value: 'lr', name: 'Liberia' },
  { value: 'ly', name: 'Libya' },
  { value: 'li', name: 'Liechtenstein' },
  { value: 'lt', name: 'Lithuania' },
  { value: 'lu', name: 'Luxembourg' },

  { value: 'mo', name: 'Macao' },
  { value: 'mg', name: 'Madagascar' },
  { value: 'mw', name: 'Malawi' },
  { value: 'my', name: 'Malaysia' },
  { value: 'mv', name: 'Maldives' },
  { value: 'mk', name: 'Mali' },
  { value: 'mt', name: 'Malta' },
  { value: 'mh', name: 'Marshall Islands' },
  { value: 'mq', name: 'Martinique' },
  { value: 'mr', name: 'Mauritania' },
  { value: 'mu', name: 'Mauritius' },
  { value: 'yt', name: 'Mayotte' },
  { value: 'mx', name: 'Mexico' },
  { value: 'fm', name: 'Micronesia (Federated States of)' },
  { value: 'md', name: 'Moldova, Republic of' },
  { value: 'mc', name: 'Monaco' },
  { value: 'mn', name: 'Mongolia' },
  { value: 'me', name: 'Montenegro' },
  { value: 'ms', name: 'Montserrat' },
  { value: 'ma', name: 'Morocco' },
  { value: 'mz', name: 'Mozambique' },
  { value: 'mm', name: 'Myanmar' },

  { value: 'na', name: 'Namibia' },
  { value: 'nr', name: 'Nauru' },
  { value: 'np', name: 'Nepal' },
  { value: 'nl', name: 'Netherlands' },
  { value: 'nc', name: 'New Caledonia' },
  { value: 'nz', name: 'New Zealand' },
  { value: 'ni', name: 'Nicaragua' },
  { value: 'ne', name: 'Niger' },
  { value: 'ng', name: 'Nigeria' },
  { value: 'nu', name: 'Niue' },
  { value: 'nf', name: 'Norfolk Island' },
  { value: 'mk', name: 'North Macedonia' },
  { value: 'mp', name: 'Northern Mariana Islands' },
  { value: 'no', name: 'Norway' },

  { value: 'om', name: 'Oman' },

  { value: 'pk', name: 'Pakistan' },
  { value: 'pw', name: 'Palau' },
  { value: 'ps', name: 'Palestine' },
  { value: 'pa', name: 'Panama' },
  { value: 'pg', name: 'Papua New Guinea' },
  { value: 'py', name: 'Paraguay' },
  { value: 'pe', name: 'Peru' },
  { value: 'ph', name: 'Philippines' },
  { value: 'pn', name: 'Pitcairn' },
  { value: 'pl', name: 'Poland' },
  { value: 'pt', name: 'Portugal' },
  { value: 'pr', name: 'Puerto Rico' },

  { value: 'qa', name: 'Qatar' },

  { value: 're', name: 'Réunion' },
  { value: 'ro', name: 'Romania' },
  { value: 'ru', name: 'Russian Federation' },
  { value: 'rw', name: 'Rwanda' },

  { value: 'bl', name: 'Saint Barthélemy' },
  { value: 'sh', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'kn', name: 'Saint Kitts and Nevis' },
  { value: 'lc', name: 'Saint Lucia' },
  { value: 'mf', name: 'Saint Martin (French part)' },
  { value: 'pm', name: 'Saint Pierre and Miquelon' },
  { value: 'vc', name: 'Saint Vincent and the Grenadines' },
  { value: 'ws', name: 'Samoa' },
  { value: 'sm', name: 'San Marino' },
  { value: 'st', name: 'Sao Tome and Principe' },
  { value: 'sa', name: 'Saudi Arabia' },
  { value: 'sn', name: 'Senegal' },
  { value: 'rs', name: 'Serbia' },
  { value: 'sc', name: 'Seychelles' },
  { value: 'sl', name: 'Sierra Leone' },
  { value: 'sg', name: 'Singapore' },
  { value: 'sx', name: 'Sint Maarten (Dutch part)' },
  { value: 'sk', name: 'Slovakia' },
  { value: 'si', name: 'Slovenia' },
  { value: 'sb', name: 'Solomon Islands' },
  { value: 'so', name: 'Somalia' },
  { value: 'za', name: 'South Africa' },
  { value: 'gs', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'ss', name: 'South Sudan' },
  { value: 'es', name: 'Spain' },
  { value: 'lk', name: 'Sri Lanka' },
  { value: 'sd', name: 'Sudan' },
  { value: 'sr', name: 'Suriname' },
  { value: 'sj', name: 'Svalbard and Jan Mayen' },
  { value: 'se', name: 'Sweden' },
  { value: 'ch', name: 'Switzerland' },
  { value: 'sy', name: 'Syrian Arab Republic' },

  { value: 'tw', name: 'Taiwan' },
  { value: 'tj', name: 'Tajikistan' },
  { value: 'tz', name: 'Tanzania, United Republic of' },
  { value: 'th', name: 'Thailand' },
  { value: 'tl', name: 'Timor-Leste' },
  { value: 'tg', name: 'Togo' },
  { value: 'tk', name: 'Tokelau' },
  { value: 'to', name: 'Tonga' },
  { value: 'tt', name: 'Trinidad' },
  { value: 'tn', name: 'Tunisia' },
  { value: 'tr', name: 'Turkey' },
  { value: 'tm', name: 'Turkmenistan' },
  { value: 'tc', name: 'Turks and Caicos Islands' },
  { value: 'tv', name: 'Tuvalu' },

  { value: 'ug', name: 'Uganda' },
  { value: 'ua', name: 'Ukraine' },
  { value: 'ae', name: 'United Arab Emirates' },
  { value: 'gb', name: 'United Kingdom' },
  { value: 'um', name: 'United States Minor Outlying Islands' },
  { value: 'us', name: 'United States of America' },
  { value: 'uy', name: 'Uruguay' },
  { value: 'uz', name: 'Uzbekistan' },

  { value: 'vu', name: 'Vanuatu' },
  { value: 've', name: 'Venezuela (Bolivarian Republic of)' },
  { value: 'vn', name: 'Viet Nam' },
  { value: 'vg', name: 'Virgin Islands (British)' },
  { value: 'vi', name: 'Virgin Islands (U.S.)' },

  { value: 'wf', name: 'Wallis and Futuna' },
  { value: 'eh', name: 'Western Sahara' },

  { value: 'ye', name: 'Yemen' },

  { value: 'zm', name: 'Zambia' },
  { value: 'zw', name: 'Zimbabwe' },
];
