import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import type { FC } from 'react';

import Label, { LabelSize } from 'core/components/Label';
import AddCircleIcon from 'core/icons/AddCircleIcon';
import AdminIcon from 'core/icons/AdminIcon';
import ArrowRightIcon from 'core/icons/ArrowRightIcon';
import AttentionStarIcon from 'core/icons/AttentionStarIcon';
import ExploreIcon from 'core/icons/ExploreIcon';
import FaceIcon from 'core/icons/FaceIcon';
import ForumsIcon from 'core/icons/ForumsIcon';
import HelpIcon from 'core/icons/HelpIcon';
import UserIcon from 'core/icons/UserIcon';
import { ColorVariant } from 'core/utils';

import UserDrawerMenuItem from 'components/UserDrawerMenuItem';
import { SITE_NAME } from 'config';
import useUser from 'hooks/data/account/useUser';
import {
  accountCreatorAttributesRoute,
  accountCreatorCategoriesRoute,
  accountCreatorLinksRoute,
  accountCreatorRoute,
  accountGeneralRoute,
  accountProfileInfoRoute,
  accountProfileRoute,
  accountRoute,
  accountSecurityRoute,
  adminRoute,
  faqRoute,
  forumsRoute,
  indexRoute,
  loginRoute,
  profileRoute,
  searchRoute,
  signupRoute,
  updatesRoute,
} from 'routes';

import s from './UserDrawerContent.module.scss';

const UserDrawerContent: FC = () => {
  const router = useRouter();
  //const { data: badgesData } = useBadges();
  const { data: userData } = useUser();

  const exploreMenuItem = (
    <UserDrawerMenuItem
      title="Explore"
      href={searchRoute()}
      icon={ExploreIcon}
      selected={router.route === searchRoute()}
    />
  );

  const forumsMenuItem = (
    <UserDrawerMenuItem
      title="Forums"
      href={forumsRoute()}
      icon={ForumsIcon}
      selected={router.route.startsWith(forumsRoute())}
    />
  );

  const accountMenuItem = (
    <UserDrawerMenuItem
      title="Account"
      href={accountRoute()}
      icon={FaceIcon}
      selected={[
        accountRoute(),
        accountProfileRoute(),
        accountProfileInfoRoute(),
        accountCreatorAttributesRoute(),
        accountCreatorCategoriesRoute(),
        accountCreatorLinksRoute(),
        accountGeneralRoute(),
        accountSecurityRoute(),
      ].includes(router.route)}
    />
  );

  const addMenuItem = (
    <UserDrawerMenuItem
      title="Add your profile"
      href={accountCreatorRoute()}
      icon={AddCircleIcon}
      selected={router.route === accountCreatorRoute()}
    />
  );

  const adminMenuItem = (
    <UserDrawerMenuItem
      title="Admin"
      href={adminRoute()}
      icon={AdminIcon}
      selected={router.route.startsWith(adminRoute())}
    />
  );

  const signupMenuItem = (
    <UserDrawerMenuItem
      title="Sign Up"
      href={signupRoute()}
      icon={UserIcon}
      selected={router.route === signupRoute()}
    />
  );

  const loginMenuItem = (
    <UserDrawerMenuItem
      title="Login"
      href={loginRoute()}
      icon={UserIcon}
      selected={router.route === loginRoute()}
    />
  );

  const faqMenuItem = (
    <UserDrawerMenuItem
      title="FAQ"
      href={faqRoute()}
      icon={HelpIcon}
      selected={router.route === faqRoute()}
    />
  );

  const updatesMenuItem = (
    <UserDrawerMenuItem
      title="What's New"
      href={updatesRoute()}
      icon={AttentionStarIcon}
      selected={router.route === updatesRoute()}
    />
  );

  return (
    <nav className={cn(s.root, !userData && s.waitingForSession)}>
      <div className={s.toolbar}>
        <Link href={indexRoute()}>
          <a className={cn(s.link, s.logo)}>{SITE_NAME}</a>
        </Link>
      </div>

      <div className={s.contentContainer}>
        <div className={s.content}>
          {userData?.user ? (
            <>
              <div className={s.user}>
                <Link href={profileRoute(userData.user.username)}>
                  <a className={s.profileLink}>
                    <div className={s.profilePicture}>
                      <div className={s.profilePictureInner}>
                        <div className={s.hover}>
                          <div className={s.icon}>
                            <ArrowRightIcon className={s.svgIcon} />
                          </div>
                        </div>
                        <Image
                          alt="Profile Picture"
                          height="90"
                          priority={true}
                          src={userData.user.profilePictureUrl}
                          width="90"
                        />
                      </div>
                    </div>
                    <div className={s.name}>{userData.user.name}</div>
                    {userData?.user?.isCreator && (
                      <Label
                        classNames={{ root: s.typeLabel }}
                        size={LabelSize.Small}
                        text="Creator"
                        variant={ColorVariant.Danger}
                      />
                    )}
                  </a>
                </Link>

                {/*<div className={cn(s.stats, s.relationshipsStats)}>
                  {userData?.user?.isCreator ? (
                    <>
                      <Link href="">
                        <a className={s.stat}>
                          <div className={s.value}>
                            {numberFormat(creatorData.creator.numSubscribers)}
                          </div>
                          <div className={s.label}>Subscribers</div>
                        </a>
                      </Link>

                      <Link href="">
                        <a className={s.stat}>
                          <div className={s.value}>
                            {numberFormat(creatorData.creator.numFollowers)}
                          </div>
                          <div className={s.label}>Followers</div>
                        </a>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link href={homeRoute()}>
                        <a className={s.stat}>
                          <div className={s.value}>
                            {numberFormat(userData.user.numSubscriptions)}
                          </div>
                          <div className={s.label}>Subscriptions</div>
                        </a>
                      </Link>
                      <Link href={homeRoute()}>
                        <a className={s.stat}>
                          <div className={s.value}>
                            {numberFormat(userData.user.numFollowing)}
                          </div>
                          <div className={s.label}>Following</div>
                        </a>
                      </Link>
                    </>
                  )}
                  </div>*/}
              </div>

              <div className={s.menu}>
                {exploreMenuItem}
                {forumsMenuItem}
                {accountMenuItem}
                {!(
                  userData.user.isCreator || userData.user.isCreatorApplicant
                ) && <>{addMenuItem}</>}
                {faqMenuItem}
                {updatesMenuItem}
                {userData.user.isStaff && <>{adminMenuItem}</>}
              </div>
            </>
          ) : (
            <div className={s.menu}>
              {signupMenuItem}
              {loginMenuItem}
              {exploreMenuItem}
              {addMenuItem}
              {forumsMenuItem}
              {faqMenuItem}
              {updatesMenuItem}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default memo(UserDrawerContent);
