export const indexRoute = () => '/';

// Auth
export const signupRoute = () => '/signup';
export const loginRoute = () => '/login';
export const logoutRoute = () => '/logout';
export const forgotPasswordRoute = () => '/forgot';
export const resetPasswordRoute = () => '/reset'; // ?uuid=xxx&code=yyy (clicked from email)
export const signupApiRoute = () => '/api/auth/signup';
export const loginApiRoute = () => '/api/auth/login';
export const logoutApiRoute = () => '/api/auth/logout';
export const forgotPasswordApiRoute = () => '/api/auth/forgot';
export const resetPasswordApiRoute = () => '/api/auth/reset';
export const verifyEmailApiRoute = () => '/api/auth/verify/email';

// Account
export const accountRoute = () => '/account'; // Redirects to /account/profile
export const accountGeneralRoute = () => '/account/general';
export const accountSecurityRoute = () => '/account/security';
export const accountProfileRoute = () => '/account/profile';
export const accountProfileInfoRoute = () => '/account/profile/info';
export const accountCreatorRoute = () => '/account/creator';
export const accountCreatorAttributesRoute = () =>
  '/account/creator/attributes';
export const accountCreatorCategoriesRoute = () =>
  '/account/creator/categories';
export const accountCreatorLinksRoute = () => '/account/creator/links';
export const accountIdentityRoute = () => '/account/identity';
export const accountTwitterRoute = () => '/account/twitter';
export const accountCreatorApiRoute = () => '/api/account/creator';
export const accountCreatorAttributesApiRoute = () =>
  '/api/account/creator/attributes';
export const accountCreatorCategoriesApiRoute = () =>
  '/api/account/creator/categories';
export const accountCreatorCategoryApiRoute = (uuid: string) =>
  `/api/account/creator/categories/${uuid}`;
export const accountCreatorLinksApiRoute = () => '/api/account/creator/links';
export const accountCreatorLinkApiRoute = (uuid: string) =>
  `/api/account/creator/links/${uuid}`;
export const accountCreatorApplicantIssueApiRoute = (uuid: string) =>
  `/api/account/creator/applicant/issues/${uuid}`;
export const accountUserApiRoute = () => '/api/account/user';
export const accountIdentityApiRoute = () => '/api/account/identity';
export const accountTwitterApiRoute = () => '/api/account/twitter';

// Search
export const searchRoute = () => '/search';
export const searchApiRoute = () => '/api/search';

export const faqRoute = () => '/faq';
export const helpRoute = () => '/help';
export const updatesRoute = () => '/updates';

// Legal
export const legalTermsRoute = () => '/legal/terms';

// Profile
export const profileRoute = (username: string) => `/${username}`;
export const profileAboutRoute = (username: string) => `/${username}/about`;
export const profileApiRoute = (username: string) => `/api/profile/${username}`;
export const profileViewApiRoute = (username: string) =>
  `/api/profile/${username}/v`;

// Forums
export const forumsRoute = () => '/forums';
export const forumThreadCreateRoute = () => '/forums/new';
export const forumThreadRoute = (slug: string) => `/forums/${slug}`;
export const forumsApiRoute = () => '/api/forums';
export const forumThreadApiRoute = (slug: string) => `/api/forums/${slug}`;
export const forumThreadViewApiRoute = (slug: string) =>
  `/api/forums/${slug}/v`;
export const forumThreadEditRoute = (slug: string) => `/forums/${slug}/edit`;
export const forumPostEditRoute = (threadSlug: string, postUuid: string) =>
  `/forums/${threadSlug}/${postUuid}/edit`;

// Admin
export const adminRoute = () => '/god';

// Admin - Creator Application
export const adminCreatorApplicantReviewsRoute = () =>
  '/god/creator-applicant-reviews';
export const adminCreatorApplicantReviewRoute = (uuid: string) =>
  `/god/creator-applicant-reviews/${uuid}`;
export const adminCreatorApplicantReviewsApiRoute = (): string =>
  '/api/god/creator-applicant-reviews';
export const adminCreatorApplicantReviewApiRoute = (uuid: string): string =>
  `/api/god/creator-applicant-reviews/${uuid}`;
export const adminCreatorApplicantReviewIssuesApiRoute = (
  uuid: string
): string => `/api/god/creator-applicant-reviews/${uuid}/issues`;
export const adminCreatorApplicantReviewIssueApiRoute = (
  reviewUuid: string,
  issueUuid: string
): string =>
  `/api/god/creator-applicant-reviews/${reviewUuid}/issues/${issueUuid}`;

// Api Routes (public, cacheable)
export const categoriesApiRoute = () => '/api/categories';

export const accountBadgesApiRoute = () => '/api/account/badges';
export const sessionApiRoute = () => '/api/session';
export const uploadPresignApiRoute = () => '/api/upload/presign';
export const uploadPublishApiRoute = () => '/api/upload/publish';
