import type { ElementType } from 'react';

import AttentionCircleIcon from '../icons/AttentionCircleIcon';
import ClockCircleIcon from '../icons/ClockCircleIcon';
import CloseCircleIcon from '../icons/CloseCircleIcon';
import TickCircleIcon from '../icons/TickCircleIcon';
import { ColorVariant } from '../utils';
import { fill as fillCreatorApplicantIssue } from './CreatorApplicantIssue';
import type CreatorApplicantIssue from './CreatorApplicantIssue';
import type { PgCreatorApplicantIssue } from './CreatorApplicantIssue';
import { fill as fillCreatorAttributes } from './CreatorAttributes';
import type CreatorAttributes from './CreatorAttributes';
import type { PgCreatorAttributes } from './CreatorAttributes';
import { fill as fillCreatorSettings } from './CreatorSettings';
import { fill as fillCreatorLink } from './CreatorLink';
import type CreatorLink from './CreatorLink';
import type { PgCreatorLink } from './CreatorLink';
import type CreatorSettings from './CreatorSettings';
import type { PgCreatorSettings } from './CreatorSettings';
import { fill as fillUser } from './User';
import type User from './User';
import type { PgUser } from './User';

export enum CreatorState {
  ApplicantPreparing = 'applicant-preparing', // created an application
  ApplicantSubmitted = 'applicant-submitted', // submitted (waiting for review)
  ApplicantInReview = 'applicant-in-review', // waiting for review
  ApplicantRejected = 'applicant-rejected', // application rejected
  ApplicantCancelled = 'applicant-cancelled', // application cancelled by user. also sets user.creator_id to null
  Active = 'active', // a normal creator
  Inactive = 'inactive', // an inactive creator (idle for 6 months)
  Suspended = 'suspended', // suspended by admin
  Terminated = 'terminated', // terminated by admin
}

export default interface Creator {
  currentMonthEarnings?: number;
  dateApproved?: string;
  dateCreated?: string;
  dateStateUpdated?: string;
  dateUpdated?: string;
  id?: number;
  numFollowers?: number;
  numForumPosts?: number;
  numLikes?: number;
  numPhotos?: number;
  numSubscribers?: number;
  numVideos?: number;
  numViews?: number;
  state?: CreatorState;
  uuid?: string;

  applicantIssues?: CreatorApplicantIssue[];
  attributes?: CreatorAttributes;
  links?: CreatorLink[];
  settings?: CreatorSettings;
  user?: User;
}

export interface PgCreator {
  current_month_earnings?: number;
  date_approved?: string | Date;
  date_created?: string | Date;
  date_state_updated?: string | Date;
  date_updated?: string | Date;
  id?: number;
  num_followers?: number;
  num_forum_posts?: number;
  num_likes?: number;
  num_photos?: number;
  num_subscribers?: number;
  num_videos?: number;
  num_views?: number;
  state?: CreatorState;
  uuid?: string;

  applicant_issues?: PgCreatorApplicantIssue[];
  attributes?: PgCreatorAttributes;
  links?: PgCreatorLink[];
  settings?: PgCreatorSettings;
  user?: PgUser;
}

export const fill = (data: PgCreator): Creator => {
  const creator: Creator = {
    currentMonthEarnings: data.current_month_earnings,
    dateApproved: data.date_approved
      ? (data.date_approved as Date).toISOString()
      : undefined,
    dateCreated: data.date_created
      ? (data.date_created as Date).toISOString()
      : undefined,
    dateStateUpdated: data.date_state_updated
      ? (data.date_state_updated as Date).toISOString()
      : undefined,
    dateUpdated: data.date_updated
      ? (data.date_updated as Date).toISOString()
      : undefined,
    id: data.id,
    numFollowers: data.num_followers,
    numForumPosts: data.num_forum_posts,
    numLikes: data.num_likes,
    numPhotos: data.num_photos,
    numSubscribers: data.num_subscribers,
    numVideos: data.num_videos,
    numViews: data.num_views,
    state: data.state,
    uuid: data.uuid,
  };

  if (data.applicant_issues) {
    creator.applicantIssues = data.applicant_issues.map((applicantIssue) =>
      fillCreatorApplicantIssue(applicantIssue)
    );
  }

  if (data.attributes) {
    creator.attributes = fillCreatorAttributes(data.attributes);
  }

  if (data.links) {
    creator.links = data.links.map((link) => fillCreatorLink(link));
  }

  if (data.settings) {
    creator.settings = fillCreatorSettings(data.settings);
  }

  if (data.user) {
    creator.user = fillUser(data.user);
  }

  return creator;
};

export const stateToColorVariant = (state: CreatorState): ColorVariant => {
  switch (state) {
    case CreatorState.ApplicantPreparing:
      return ColorVariant.Warning;

    case CreatorState.ApplicantSubmitted:
      return ColorVariant.Warning;

    case CreatorState.ApplicantInReview:
      return ColorVariant.Warning;

    case CreatorState.ApplicantRejected:
      return ColorVariant.Danger;

    case CreatorState.ApplicantCancelled:
      return ColorVariant.Neutral;

    case CreatorState.Active:
      return ColorVariant.Success;

    case CreatorState.Inactive:
      return ColorVariant.Neutral;

    case CreatorState.Suspended:
      return ColorVariant.Danger;

    case CreatorState.Terminated:
      return ColorVariant.Danger;

    default:
      return ColorVariant.Neutral;
  }
};

export const stateToTitleString = (state: CreatorState): string => {
  switch (state) {
    case CreatorState.ApplicantPreparing:
      return 'Preparing';

    case CreatorState.ApplicantSubmitted:
      return 'Waiting for Review';

    case CreatorState.ApplicantInReview:
      return 'In Review';

    case CreatorState.ApplicantRejected:
      return 'Issues Found';

    case CreatorState.ApplicantCancelled:
      return 'Cancelled';

    case CreatorState.Active:
      return 'Active';

    case CreatorState.Inactive:
      return 'Inactive';

    case CreatorState.Suspended:
      return 'Suspended';

    case CreatorState.Terminated:
      return 'Terminated';

    default:
      return 'Unknown';
  }
};

export const stateToDescriptionString = (state: CreatorState): string => {
  switch (state) {
    case CreatorState.ApplicantPreparing:
      return 'Complete these tasks and become a creator.';

    case CreatorState.ApplicantSubmitted:
      return 'Your application will be reviewed within 24 hours.';

    case CreatorState.ApplicantInReview:
      return 'Your application is currently being reviewed.';

    case CreatorState.ApplicantRejected:
      return 'Your application has some issues. Please resolve them and resubmit.';

    case CreatorState.ApplicantCancelled:
      return 'Your application has been cancelled.';

    case CreatorState.Active:
      return 'Your creator account is active.';

    case CreatorState.Inactive:
      return 'Your creator account is inactive.';

    case CreatorState.Suspended:
      return 'Your creator account has been suspended.';

    case CreatorState.Terminated:
      return 'Your creator account has been terminated.';

    default:
      return 'Become a creator and add yourself to the database.';
  }
};

export const stateToIcon = (state: CreatorState): ElementType => {
  switch (state) {
    case CreatorState.ApplicantPreparing:
      return ClockCircleIcon;

    case CreatorState.ApplicantSubmitted:
      return ClockCircleIcon;

    case CreatorState.ApplicantInReview:
      return ClockCircleIcon;

    case CreatorState.ApplicantRejected:
      return CloseCircleIcon;

    case CreatorState.ApplicantCancelled:
      return CloseCircleIcon;

    case CreatorState.Active:
      return TickCircleIcon;

    case CreatorState.Inactive:
      return AttentionCircleIcon;

    case CreatorState.Suspended:
      return AttentionCircleIcon;

    case CreatorState.Terminated:
      return AttentionCircleIcon;

    default:
      return null;
  }
};
